import React, { useEffect, useState } from "react"
import { ResultItemStyled } from "../Search/index"
import { graphql, Link } from "gatsby"

import { theme } from "../Layout/GlobalStyle"
import PropTypes from "prop-types"
import { HerbPicturePropTypes } from "../../../types/HerbPicturePropTypes"

function HerbResultItemStatic({ staticHerb, forceLoadPicture }) {
const {pictures = [{src: ""}]} = staticHerb;
let picture = pictures[0]
 
  return (
    <ResultItemStyled
      background={picture.src
      }
    >
        {staticHerb.slug ? (
          <Link to={`/herb/${staticHerb.slug}`}>
            <div className="result-image">
              <img
                alt={`${staticHerb.name} ${staticHerb.latinName} thumbnail`}
                src={picture ? picture.src : theme.defaultHerbListIcon}
              />
            </div>
          </Link>
        ) : (
          <div className="result-image">
            <img
              alt={`${staticHerb.name} ${staticHerb.latinName} thumbnail`}
              src={picture ? picture.src : theme.defaultHerbListIcon}
            />
          </div>
        )}

      <div className="result-information">
        {staticHerb.slug ? (
          <Link className="result-title-link" to={`/herb/${staticHerb.slug}`}>
            <h6 className="result-title">{staticHerb.name ? staticHerb.name : ""}</h6>
          </Link>
        ) : (
          <h6 className="result-title">{staticHerb.name ? staticHerb.name : ""}</h6>
        )}
        <div className="result-latin-name">
          {staticHerb.latinName ? staticHerb.latinName : ""}
        </div>
        <p className="result-description">
          {staticHerb.description ? staticHerb.description.substring(0, 80) + "..." : ""}
        </p>
        <div className="click-for-more">
          {staticHerb.description ? (
            <Link to={`/herb/${staticHerb.slug}`}>Click here to read more</Link>
          ) : (
            ""
          )}
        </div>
      </div>
    </ResultItemStyled>
  )
}


HerbResultItemStatic.propTypes = {
  forceLoadPicture: PropTypes.bool,
  staticHerb: PropTypes.shape({
    created_at: PropTypes.string,
    description: PropTypes.string,
    pictures: PropTypes.arrayOf(HerbPicturePropTypes),
    name: PropTypes.string,
    slug: PropTypes.string,
    mongodb_id: PropTypes.string,
  }),
}

export default HerbResultItemStatic
