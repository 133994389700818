import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { BreakpointMobile, PaddingLeftRightDesktop } from '../Layout/GlobalStyle'
import withLocation from '../common/withLocation'
import HerbResultItem from '../HerbResultItem'


const Container = styled.div`
    margin: auto;
    width: 100%;
    /* max-width: var(--MaxPageWidth); */
  
    display: flex;
    flex-direction: column;
`

const LoadMoreListButton = styled.button`
    /* background: var(--Color1); */
    width: 320px;
    height: 30px;
    color: white;
    background: transparent;
    font-family: var(--Font2);
    border-radius: 4px;
    font-size: small;
    text-transform: uppercase;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid var(--Color1);
    color: var(--Color1);
    cursor: pointer;

    :hover{
        background: var(--Color1);
        color: white;
    }

    

`

const ResultBar = styled.div`
    margin-bottom: 20px;
    font-size: 13px;
    font-family: var(--Font2);
    color: #222220;
    border-bottom: 1px solid #CCCCCC;
    padding-bottom: 10px;
    padding-top: 10px;
    height: 40px;
    display: flex;
    align-items: center;
    .highlighted-text{
        color: var(--Color2);
        font-weight: bold;
    }

`

const ResultList = styled.div`
    margin-bottom: 20px;
`

export const ResultItemStyled = styled.div`
    padding-bottom: 15px;
    padding-top: 15px;



    /* :first-of-type{
        padding-top: 0px;
    } */
    display: grid;
    grid-template-columns: 130px 1fr;
    grid-gap: 20px;
    overflow: hidden;

    height: 150px;

    position: relative;


    .result-information{
        display: flex;
        flex-direction: column;
        text-overflow: ellipsis;
        height: 130px;
    }

    .result-image{
        border-radius: 4px;
        overflow: hidden;
        img{
            height: 130px;
            width: inherit;
            border-radius: 4px;
            object-fit: cover;
            width: 130px;
        }
        transition: 0.3s ease-in-out;
        width: 100%;
        overflow: hidden;
        height: 130px;
        background-size: cover;
        background-position: 50%;

    }

    .result-title{
        padding-top: 0px;
        margin-bottom: 0px;
        padding-bottom: 0px;
        margin-top: 0px;
        font-size: 20px;
        font-family: var(--Font1);
        line-height: 34px;
        font-weight: bold;
        color: #222220;
        cursor: pointer;

        :hover{
            text-decoration: underline;
        }

    }

    .result-titles{
        display: flex;
        flex-direction: column;
    }

    .result-latin-name{
        line-height: 10px;
        font-size: 10px;
        font-style: italic;
        padding-bottom: 7px;
        color: #222220;
    }

    .result-description{
        position: relative;
        margin-top: 0px;
        font-family: var(--Font2);
        color: #4D4D4D;   
        text-overflow: ellipsis;
        font-size: var(--ParagraphFontSize);
        line-height: var(--ParagraphLineHeight);
        margin-top: 0px;
        line-height: 16px;
        margin-bottom: 0px;
        color: #222220;
    }

    .click-for-more{
        padding-top: 10px;
        /* position: absolute; */
        bottom: 20px;
        font-size:  calc(var(--ParagraphFontSize) - 4px);
        line-height: calc(var(--ParagraphLineHeight) - 4px);

        a{
            color: var(--Color2);
            text-decoration: underline;
        }
    }


    @media screen and (max-width: ${BreakpointMobile + 'px'}) {
        grid-template-columns: 100px 1fr;
        padding-bottom: 5px;
        padding-top: 15px;
        height: initial;
        max-height: initial;
        grid-gap: 10px;

        .result-title{
            margin-bottom: 1px;

        }

       


        .result-titles{
            height: initial;
        }

        .result-latin-name{
           display: none;

        }

        .result-information{
            min-height: 100px;
            max-height: 100px;
            a{
                height: 33px;
            }
        }

        .result-description{
            margin-bottom: 0px;
            height: 100%;
            font-size:  calc(var(--ParagraphFontSize) - 2px);
            line-height: calc(var(--ParagraphLineHeight) - 2px);

            /* font-size: 10px; */
        }

        .click-for-more{
            display: none;
        }

        .result-title{
            padding-top: 0px;
            margin-top: 0px;
            /* font-size: 18px; */
            padding-bottom: 0px;


        }

        .result-title-link{
            line-height: initial;

        }

        .result-image{
            height: 100px;
            width: 100px;
        }  

        .click-for-more{
            padding-top: 5px;
        }
    }

`


function Search(props) {
    let { allMongodbHerbsHerbs } = props.data;

    let [items, setItems] = useState(allMongodbHerbsHerbs.nodes)
    let [limit, setLimit] = useState(10)

    let [results, setResults] = useState([])

    useEffect(() => {
        let isSubscribed = true;
        if (isSubscribed) {
            loadData()
        }
        return () => {
            isSubscribed = false;
        };
    }, [props.search.q])


    const filterResults = () => {
        let { q } = props.search;
        if (q) {
            let tempResults = items.filter((item => item.name.toLowerCase().includes(q.toLowerCase())))
            console.log(tempResults)
            setResults(tempResults)
        }

    }

    const loadData = () => {

        filterResults()
    }

    let renderResults = results.length ? results.slice(0, limit).map((item, index) => {
        return (
            <HerbResultItem key={index} item={item} />
        )
    }) : !props.search.q ? <div>Please type something in the search bar</div> : ""

    let { query } = props;

    return (
        <Container>
            <ResultBar>
                <span className="result-text">
                    {results.length ? <>{limit > results.length ? results.length : limit} out of {results.length} results for <span className="highlighted-text">“{props.search.q}” </span> </> : <>No results found <span className="highlighted-text"> {props.search.q} </span></>}
                </span>
            </ResultBar>
            <ResultList>
                {renderResults}
            </ResultList>
            {limit < results.length ? <LoadMoreListButton onClick={() => setLimit(limit + 5)}>Click to See more results</LoadMoreListButton> : ""}
        </Container>
    )
}

Search.defaultProps = {
    query: {
        q: ""

    }
}




export default withLocation(Search)