import React from "react"
import {
  BlogContainer,
  CustomH4One,
  GridArticleLayoutOne,
  GridLayoutOne,
} from "./styles"
import styled from 'styled-components'
import BlogSlider from "./BlogSlider"
import { graphql, useStaticQuery } from "gatsby"
import ArticleItem from "./ArticleItem"
import PopularHerbsStatic from "../PopularHerbs/PopularHerbsStatic"
import CountDownBanner from "../CountDownBanner/CountDownBanner"
import Advertisment from "../Advertisement"


const Container = styled.div`
.recent-articles-container{
  width: 100%;
}
`

function Blog() {
  let {
    allMongodbHerbsBlogarticles: { edges: posts },
  } = useStaticQuery(graphql`
    query {
      allMongodbHerbsBlogarticles(
        filter: {
          sticky: { eq: true }
          safeToPublish: { eq: true }
          draft: { eq: false }
        }
      ) {
        edges {
          node {
            id
            mongodb_id
            ogImage1200x628
            safeToPublish
            slug
            sticky
            title
            markup
            metaDescription
            modified_at
            draft
          }
        }
      }
    }
  `)

  let renderedPosts = posts.map((item, index) => {
    return <ArticleItem {...item.node} key={index} />
  })

  return (
    <Container>
      <BlogSlider posts={posts} />
      <BlogContainer>
        <span style={{ padding: 20 }}></span>
        <GridLayoutOne>
          <div className="recent-articles-container">
            <CustomH4One>Recent Articles </CustomH4One>
            <GridArticleLayoutOne>{renderedPosts}</GridArticleLayoutOne>
            {/* <PopularHerbsStatic /> */}
          </div>
          <div className="recent-articles-container">
            {" "}
            <CountDownBanner marginTop={"0px"} />
            <Advertisment />
          </div>
          <div></div>
        </GridLayoutOne>
      </BlogContainer>
    </Container>
  )
}

export default Blog
