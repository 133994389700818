import React, { useEffect, useState, useCallback, useMemo } from "react"
import { ResultItemStyled } from "../Search/index"
import { graphql, Link } from "gatsby"
import VisibilitySensor from "react-visibility-sensor"
import {
  getHerbBySlug,
  getHerbMainPictureByHerbID,
} from "../../services/herb.services"
import { theme } from "../Layout/GlobalStyle"
import PropTypes from "prop-types"

function HerbResultItem({ item, forceLoadPicture }) {
  const [loading, setLoading] = useState(false)
  const [picture, setPicture] = useState(null)
  const [hasCheckedForPicture, setHasCheckedForPicture] = useState(false)

  const loadData = useCallback(async (isSubscribed) => {
    if (item && item._id && !loading && !hasCheckedForPicture) {


      setLoading(true)
      getHerbMainPictureByHerbID(item._id)
        .then(res => {
          if (res.status === 200 && isSubscribed) {
            setLoading(false)
            setPicture(res.data)
            setHasCheckedForPicture(true)
          }
        })
        .catch(err => {
          setHasCheckedForPicture(true)
          setLoading(false)
          throw err
        })
    }
  }, [item._id, hasCheckedForPicture])

  useEffect(() => {
    let isSubscribed = true;
    if (isSubscribed && forceLoadPicture === true && picture === null) {
      loadData(isSubscribed)
    }

    return () => isSubscribed = false;
  }, [forceLoadPicture, picture])

  const onVisibilityChange = isVisible => {
    if (
      isVisible === true &&
      picture === null &&
      !hasCheckedForPicture &&
      !loading &&
      item._id
    ) {
      loadData(true)
    }
  }

  const backgroundForResultItem = useMemo(() => typeof picture !== null && picture && picture.src ? picture.src : theme.defaultHerbListIcon, [picture])

  return (
    <ResultItemStyled
      background={backgroundForResultItem}
    >
      <VisibilitySensor onChange={e => onVisibilityChange(e)}>
        {item.slug ? (
          <Link to={`/herb/${item.slug}`}>
            <div className="result-image">
              <img
                alt={`${item.name} ${item.latinName} thumbnail`}
                src={picture ? picture.src : theme.defaultHerbListIcon}
              />
            </div>
          </Link>
        ) : (
          <div className="result-image">
            <img
              alt={`${item.name} ${item.latinName} thumbnail`}
              src={picture ? picture.src : theme.defaultHerbListIcon}
            />
          </div>
        )}
      </VisibilitySensor>

      <div className="result-information">
        {item.slug ? (
          <Link className="result-title-link" to={`/herb/${item.slug}`}>
            <h6 className="result-title">{item.name ? item.name : ""}</h6>
          </Link>
        ) : (
          <h6 className="result-title">{item.name ? item.name : ""}</h6>
        )}
        <div className="result-latin-name">
          {item.latinName ? item.latinName : ""}
        </div>
        <p className="result-description">
          {item.description ? item.description.substring(0, 80) + "..." : ""}
        </p>
        <div className="click-for-more">
          {item.description ? (
            <Link to={`/herb/${item.slug}`}>Click here to read more</Link>
          ) : (
            ""
          )}
        </div>
      </div>
    </ResultItemStyled>
  )
}

HerbResultItem.defaultProps = {
  pictures: [{ src: "" }],
  description: "",
  slug: "",
  latinName: "",
  name: "",
}

HerbResultItem.propTypes = {
  forceLoadPicture: PropTypes.bool,
  item: PropTypes.shape({
    created_at: PropTypes.string,
    description: PropTypes.string,
    name: PropTypes.string,
    slug: PropTypes.string,
    _id: PropTypes.string,
  }),
}

export default HerbResultItem
