import React, { useState, useEffect, useContext } from 'react'
import styled from 'styled-components'
import Slide from './Slide'
import Loadable from 'react-loadable';
import PropTypes from "prop-types"
import { BreakpointMobile, BreakpointTablet } from '../../Layout/GlobalStyle';


const ReactInterval = Loadable({
    loader: () => import('react-interval'),
    loading: () => <div></div>,
});

const BlogSliderWrapper = styled.div`

    margin: auto;
    max-width: var(--maxPageWidthBlog);
    padding-left: var(--paddingLeftRightBlog);
    padding-right: var(--paddingLeftRightBlog);
    /* padding-top: var(--PaddingContentTop); */


    @media only screen and (max-width: ${BreakpointTablet + 'px'}){
        max-width: initial;
        padding-left: initial;
        padding-right: initial;

    }
`


const Container = styled.div`
    width: 100%;
    background: #ccc;
    display: flex;
    position: relative;
    height: 450px;
    margin-top: var(--PaddingContentTop);
    max-width: var(--PageMaxWidth);
    margin: auto;

 

    .backdrop{
        width: 100%;
        background: rgba(0, 0, 0, 0.5);
        position: absolute;
        height: 100%;
        z-index: 500;
    }


    .dot-wrapper{
        position: absolute;
        display: flex;
        justify-content: center;
        bottom: 10px;
        width: 100%;

    }

    .dot-container{
        position: relative;
        display: grid;
        min-width: 70px;
        max-width: 200px;
        /* display: flex;
        justify-content: center;
        align-items: center; */
        top: 5;
        grid-gap: 5px;
        grid-template-columns: repeat(auto-fit, minmax(10px, 1fr));
    }

    @media only screen and (max-width: ${BreakpointMobile + 200 + 'px'}){
        height: 300px;
        #slideTitle{
            font-size: xx-large;
            line-height: 45px;
            padding-left: 15px;
        }
    }

    

`

export const SlideWrapper = styled.div`
    width: 100%;
    max-width: var(--MaxWidthPage);
    margin: auto;
    padding-left: var(--paddingLeftRightBlog);
  padding-right: var(--paddingLeftRightBlog);
`

const Dot = styled.div`
    display: block;
    background: white;
    width: 10px;
    display: flex;
    margin: auto;
    justify-content: center;
    align-items: center;
    height: 10px;
    border-radius: 5px;
    cursor: pointer;
    ${props => props.active ? `opacity: 1;` : "opacity: 0.5;"}
    z-index: 600;

    @media only screen and (max-width: ${BreakpointMobile + 'px'}){
        width: 7px;
        height: 7px;
    }
`


 function BlogSlider(props) {
    let {posts} = props;
    let [current, setCurrent] = useState(0)
    let [active, setActive] = useState(true)
    let [locked, setLocked] = useState(false)
    let [slides, setSlides] = useState([])

    useEffect(() => {
        let isSubscribed = true;
        if(isSubscribed){
            setSlides(posts)
        }

        return () => isSubscribed = false;
    }, [props.posts])
    // let slides = [
    //     {
    //         title: "Be different, be better",
    //         background: theme.Slide1,
    //         video_url: "https://www.facebook.com/prospectbomb/videos/690580111145936/"
    //     }, 
    //     {
    //         title: "hey",
    //         background: "https://images.unsplash.com/photo-1460919920543-d8c45f4bd621?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=3450&q=80"
    //     }
    // ]





    const slideLeft = () => {
        if(!locked && slides.length){
        let len = slides.length;
        let newCurrent = current - 1;
        if (current === 0) {
            let lastElement = len - 1;
            setCurrent(lastElement)
        } else {
            setCurrent(newCurrent)
        }
    }
    }

    const slideRight = () => {
        if(!locked && slides.length){
            let len = slides.length;
            let newCurrent = current + 1;
            if (current === len - 1) {
                setCurrent(0)
            } else {
                setCurrent(newCurrent)
            }
        }
    }




    const renderSlides = slides.length ? slides.map((slide, index) => {
        return (<Slide setLocked={setLocked} slideLeft={slideLeft} slideRight={slideRight} visible={index === current? true: false} slide={slide.node} key={index} />)
    }) : ""



    const renderDots = slides.length ? slides.map((slide, index) => { return <Dot onClick={() => setCurrent(index)} active={index === current} key={index} /> }) : ""

    return (
        <BlogSliderWrapper>
            <Container active={active} onBlur={() => setActive(true)} onFocus={() => setActive(false)} onMouseOver={() => setActive(false)} onMouseOut={() => setActive(true)}>
                <ReactInterval {...{ timeout: 5000, enabled: active }}
                    callback={() => slideRight()}
                />
                {renderSlides}
                <div className="dot-wrapper">
                    <div className="dot-container">
                        {renderDots}
                    </div>
                </div>
            </Container>
        </BlogSliderWrapper>

    )
}




BlogSlider.defaultProps = {
    posts: []
}

BlogSlider.propTypes = {
    posts: PropTypes.array.isRequired
}

export default BlogSlider