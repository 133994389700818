import React from 'react'
import styled from 'styled-components'
import {Link} from 'gatsby'
import { BLOG_POST } from '../../Layout/routes'
import { BreakpointMobile, BreakpointSmallMobile, theme } from '../../Layout/GlobalStyle'


const Container = styled.div`
    position: relative;

    #slideTitle{
        :hover{
            text-decoration: underline;
            text-decoration-thickness: 2px;

        }
    }

.slide-wrapper{
    padding-left: var(--paddingLeftRightBlog);
  padding-right: var(--paddingLeftRightBlog);
    width: 100%;
    margin: auto;
    max-width: var(--MaxWidthPage);
    position: relative;
    z-index: 600;
    height: inherit;
    display: flex;

    .slide-blurb{
        color: white;
        font-family: var(--Font1);
        display: flex;
        align-items: center;
        height: 100%;
        width: 100%;

        position: relative;
        .slide-title{
            display: block;
            line-height: 65px;
            font-size: 48px;
            padding-left: 20px;
            font-weight: 600;
            text-shadow: 2px 2px rgba(0, 0, 0, 0.5);
            position: relative;
            color: white;
            font-family: var(--Font1);
            z-index: 50;
            max-width: 400px;
            a{
                color: inherit;
                font-family: inherit;
            }
        }
    }

    .arrow-right{
        top: 0px;
        right: 0px;
    }

    .arrow-left{
        top: 0px;
        left: 0px;
    }

    .arrow{
        cursor: pointer;
        ${props => props.active === false ? "opacity: 1;" : "opacity: 0.8;"};
        position: absolute;
        width: 50px;
        height: 50px;
        background: none;
        color: white;
        border: 0px solid transparent;
        font-size: 50px;
        overflow: none;
        outline: none;
        display: flex;
        justify-content: center;
        align-items: center;
        height: inherit;
        transition: 0.2s ease-in-out;

        :active{
            opacity: 1;
            background: rgba(0, 0, 0, 0.3);
        }
    }
}


    display: flex;
    flex-direction: column;
    width: 100%;
    height: inherit;
    background-repeat: no-repeat;
    background-position: 50%;
    background-size: cover;
    background-image: ${props => props.background ? `url(${props.background});` : ""};

    z-index: ${props => props.active === true ? "1;" : "-1;"}; 
    position: ${props => props.active === true ? "relative;" : "absolute;"}; 


    @media only screen and (max-width: ${BreakpointMobile + "px"}) {
        .slide-wrapper{
            .slide-blurb{
                font-size: medium;

            }

    
            .arrow{
                width: 30px;
                top: 0px;
                padding-left: 10px;
                padding-right: 10px;
                height: inherit;
                img{
                    position: relative;
                    display: block;
                }
            }

            .arrow-right{
                right: 0px;
            }

            .arrow-left{
                left: 0px;
            }
        }

    }

    @media only screen and(max-width: ${BreakpointSmallMobile + 'px'}) {
        #slideTitle{
            font-size: 12px !important;
        }
            .slide-wrapper{
                .slide-blurb{
                    .slide-title{
                            
                            font-size:18px !important;
                    }
                }  
            }
    }

    @media only screen and (max-width: ${BreakpointMobile + 200 + 'px'}){
        .arrow{
            width: 20px;
            height: 20px;
        }
    }
`



function Slide(props) {
    let { visible, background, slideLeft, slideRight, slide } = props;
    let {ogImage1200x628} = slide;
    return (
        <Container active={visible} background={ogImage1200x628} >
            <div className="backdrop" />
            <div className="slide-wrapper">
                <button onClick={() => slideLeft()} className="arrow-left arrow">
                    <img alt="arrow-left" src={theme.icons.arrowLeft} width={20} />
                </button>
                <div className="slide-blurb">
                    <div id="slideTitle" className="slide-title">
                        <Link to={`${BLOG_POST}/${slide.slug}`}>

                        {slide.title}
                        </Link>

                    </div>
                </div>
                <button onClick={() => slideRight()} className="arrow-right arrow">
                    <img alt="arrow-right" src={theme.icons.arrowRight} width={20} />
                </button>
            </div>
        </Container>
    )
}

Slide.defaultProps = {
    slide: {
        date: "",
        postId: 0,
        title: "",
        slug: "",
        tags: [],
        featured_media: {
            source_url: ""
        },
        
        author: {
            lastName: "",
            firstName: ""
        }
    }
}

export default Slide;