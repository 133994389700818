import PropTypes from 'prop-types'

export const HerbPicturePropTypes = PropTypes.shape({
    url: PropTypes.oneOfType([
        PropTypes.string.isRequired,
        PropTypes.number.isRequired,
      ]),
      caption: PropTypes.oneOfType([
        PropTypes.string.isRequired,
        PropTypes.number.isRequired,
      ]),
      alt_text: PropTypes.oneOfType([
        PropTypes.string.isRequired,
        PropTypes.number.isRequired,
      ]),
      order: PropTypes.number,
      taken_by: PropTypes.string,
      src: PropTypes.string.isRequired
})

