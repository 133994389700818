import React from 'react'
import Blog from '../components/Blog'

import Layout from "../components/Layout"
import BlogLayout from '../components/Layout/BlogLayout'





const BlogPage  = () => (
  <BlogLayout seoProps={{title: "Blog"}}>
        <Blog />
  </BlogLayout>
)

export default BlogPage