import React, {useState, useEffect} from 'react'
import { useStaticQuery, graphql, Link } from "gatsby"
import HerbResultItemStatic from '../HerbResultItem/HerbResultItemStatic'
import { SectionLabelOne } from '../Layout/GlobalStyle'
import { getRecentlyViewedHerbs } from '../../services/herb.services'
import LoadingSpinner2 from '../common/LoadingContainer/LoadingSpinner2';
const defaultLimit = 5

function PopularHerbsStatic() {
    let data = useStaticQuery(graphql`
    query{
        popularHerbsList:allMongodbHerbsHerbs(sort: {fields: favoritedCount}, limit: 5, filter: {safeToPublish: {eq: true}, draft: {eq: false}, pictures: {}}) {
            edges {
              node {
                id
                slug
                name
                created_at
                mongodb_id
                description
                pictures {
                  url
                  caption
                  src
                  taken_by
                }
              }
            }
          }
    }
    `)
const {popularHerbsList} = data;
console.log(popularHerbsList, 'this is popular herb list')

    let renderedItems = popularHerbsList? popularHerbsList.edges.map((item, index) => {
        return(<HerbResultItemStatic key={index} staticHerb={item.node} />)
    }): null

    // END OF RENDER ITEMS

    return (
        <div>
            <SectionLabelOne paddingBottom={1}>
                Popular Herbs
            </SectionLabelOne>
            { renderedItems}
        </div>
    )
}

export default PopularHerbsStatic
