import React from 'react'
import styled from 'styled-components'
import {Link} from 'gatsby'
import { BreakpointMobile, BreakpointTablet } from '../../Layout/GlobalStyle'
import { BLOG_POST } from '../../Layout/routes'


const Container = styled.div`
    display: flex;
    height: 147.07px;
    transition: 0.2s ease-in-out;
    .entire-meta-link{
        display: flex;
        width: 100%;
    }   

    .post-content-wrap{
        padding-left: 20px;
    }

    .post-title{
        font-size: 22px;
        color: black;
        font-family: var(--Font1);
        line-height: 26px;
        margin-bottom: 0px;
        width: 90%;

        
    }

    .excerpt{
        font-size: 14px;
        line-height: 18px;
        text-overflow: ellipsis;
        font-family: var(--Font2);
        margin-top: 5px;
        color: black;
        width: 90%;
    }   


    :hover{
        .post-title{
            color: var(--Color2);
        }
    }

    @media only screen and (max-width: ${BreakpointTablet + 'px'}){
        height: initial;
        .entire-meta-link{
           
        }
        .post-content-wrap{
            
        }

        .post-title{
            font-size: 18px;
            margin-top: 0px;
            color: black;
            font-family: var(--Font1);
            line-height: 26px;
            margin-bottom: 0px;
        }

        .excerpt{
            font-size: 13px;
        }
    }

    @media only screen and (max-width: ${BreakpointMobile + 'px'}) {
        .entire-meta-link{
            width: 100%;
            flex-direction: column;
            align-items: left;
        }

        .post-title{
            text-align: left;
            font-size: 20px;
        }

        .post-content-wrap{
            padding-top: 10px;
            padding-left: 0px;
        }

        .excerpt{
            display: none;
        }
    }

`

const PostFeaturedImageWrap = styled.div`
    min-width: 280px;
    max-width: 280px;
    height: 147.07px;
    border-radius: 4px;
    overflow: hidden;


        img, source{
            min-width: 280px;
            max-width: 280px;
            min-height: 147.07px;
            border-radius: 4px;
        }
    

    @media only screen and (max-width: ${BreakpointMobile + 'px'}) {
        width: 100%;
        min-width: 100%;
        max-width: 100%;
        height: auto;
   
            img, source{
                min-width: inherit;
                max-width: inherit;
                height: auto;
                
            }
        
    }

`

 function ArticleItem(props) {
    let limitedExcerpt = props.metaDescription.replace(/<p[^>]*>/g, "").replace('</p>', '').replace("&#8217;", "'") .substring(0, 155)
    let sourceURL = props.ogImage1200x628
    let altText = "s"
    return (
        <Container>
            <Link to={`${BLOG_POST}/${props.slug}`} className="entire-meta-link">
            <PostFeaturedImageWrap src={sourceURL}>
                <img height={'auto'} alt={altText} src={sourceURL} />
            </PostFeaturedImageWrap>
                <div className="post-content-wrap">
                    <div className="post-header">
                    <h3 className="post-title"> {props.title} </h3>
                    </div>
                    <p  className="excerpt">
                        {limitedExcerpt}
                    </p>
                </div>
            </Link>
        </Container>
    )
}


ArticleItem.defaultProps = {
  date: "",
  postId: "",
  terms: [],
  title: "",
  excerpt: "",
  slug: "",
  description: "",
  featured_media: null,
  featuredImage: null,
  author: {
      lastName: "",
      firstName: "",
      id: "",
      avatar: {
          url: ""
      }
  }
};


export default ArticleItem;